import React, { useEffect, useState } from "react";
import Image from "../../components/Image";
import AbsoluteView from "../../components/AbsoluteView";
import Text from "../../components/Text";
import Input from "../../components/Input";
import StaticView from "../../components/StaticView";
import useSound from "../../hooks/useSound";
import { click } from "../../config";
import MatchSession from "../../Storage/sessionStorage/MatchSession";
import { useNavigate } from "react-router-dom";
import CustomLocal from "../../Storage/localStorage/CustomLocal";
import { SaveButton, SaveNotification } from "../../components/SaveNotification";

function CustomView() {
  const navigate = useNavigate();
  const clickSound = useSound(click);

  const [p1Name, setP1Name] = useState(CustomLocal.getPlayerName("p1"));
  const [p2Name, setP2Name] = useState(CustomLocal.getPlayerName("p2"));
  const [p1PionColor, setP1PionColor] = useState(
    CustomLocal.getPionColor("p1")
  );
  const [p2PionColor, setP2PionColor] = useState(
    CustomLocal.getPionColor("p2")
  );
  const [matchTheme, setMatchTheme] = useState(CustomLocal.getMatchTheme());

  useEffect(() => {
    CustomLocal.setPlayerName("p1", p1Name);
    CustomLocal.setPlayerName("p2", p2Name);
    CustomLocal.setPionColor(p1PionColor, "p1");
    CustomLocal.setPionColor(p2PionColor, "p2");
    CustomLocal.setMatchTheme(matchTheme);
    return () => {
      MatchSession.clear().update();
    };
  }, [p1Name, p2Name, p1PionColor, p2PionColor, matchTheme]);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timer;
    if (isVisible) {
      timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [isVisible]);

  const handleSave = () => {
    clickSound.play();
    CustomLocal.update();
    setIsVisible(true);
  };

  // const setPionColor = function (player, color) {
  //   clickSound.play();
  //   player.toUpperCase() === "p1"
  //     ? setP1PionColor(color)
  //     : setP2PionColor(color);
  // };

  return (
    <>
      <AbsoluteView position="topleft" padding="1.5rem">
        <Image
          src={"/icn/arrow-back.svg"}
          width="1.5rem"
          cursor="pointer"
          onClick={() => {
            clickSound.play();
            navigate(-1);
          }}
        />
      </AbsoluteView>

      <StaticView margin="100px auto 0 auto" width="max-content">
        <Text
          text="Sesuaikan"
          textAlign="center"
          fontSize="32px"
          fontWeight="bold"
        />

        <StaticView padding="40px 0">
          <Text
            text="Player 1"
            fontSize="20px"
            fontWeight="bold"
            textAlign="center"
          />
          <StaticView>
            <Text text="Nama" padding="16px" textAlign="center" />
            <Input
              className={"input-p1-name"}
              value={CustomLocal.getPlayerName("p1")}
              type="text"
              textAlign="center"
              margin="0 auto 0 auto"
              width="200px"
              height="28px"
              maxLength="12"
              display="block"
              bordRadius="4px"
              onBlur={(e) => {
                setP1Name(e.target.value);
              }}
            />
          </StaticView>
          <StaticView>
            <Text text="Warna Pion" padding="16px" textAlign="center" />
            <StaticView display="flex" justifyContent="center">
              <Image
                className={`${
                  p1PionColor === "brown" ? "image-clicked" : "image-click"
                }`}
                src={"/icn/pion-brown.svg"}
                width="28px"
                margin="0 8px"
                cursor="pointer"
                onClick={() => {
                  clickSound.play();
                  setP1PionColor("brown");
                }}
              />
              <Image
                className={`${
                  p1PionColor === "gray" ? "image-clicked" : "image-click"
                }`}
                src={"/icn/pion-gray.svg"}
                width="28px"
                margin="0 8px"
                cursor="pointer"
                onClick={() => {
                  clickSound.play();
                  setP1PionColor("gray");
                }}
              />
              <Image
                className={`${
                  p1PionColor === "blue" ? "image-clicked" : "image-click"
                }`}
                src={"/icn/pion-blue.svg"}
                width="28px"
                margin="0 8px"
                cursor="pointer"
                onClick={() => {
                  clickSound.play();
                  setP1PionColor("blue");
                }}
              />
              <Image
                className={`${
                  p1PionColor === "green" ? "image-clicked" : "image-click"
                }`}
                src={"/icn/pion-green.svg"}
                width="28px"
                margin="0 8px"
                cursor="pointer"
                onClick={() => {
                  clickSound.play();
                  setP1PionColor("green");
                }}
              />
              <Image
                className={`${
                  p1PionColor === "light-brown"
                    ? "image-clicked"
                    : "image-click"
                }`}
                src={"/icn/pion-light-brown.svg"}
                width="28px"
                margin="0 8px"
                cursor="pointer"
                onClick={() => {
                  clickSound.play();
                  setP1PionColor("light-brown");
                }}
              />
            </StaticView>
          </StaticView>
        </StaticView>
        <StaticView>
          <Text
            text="Player 2"
            fontWeight="bold"
            fontSize="20px"
            textAlign="center"
          />
          <StaticView>
            <Text text="Nama" padding="16px" textAlign="center" />
            <Input
              className={"input-p2-name"}
              value={CustomLocal.getPlayerName("p2")}
              type="text"
              textAlign="center"
              margin="0 auto 0 auto"
              width="200px"
              height="28px"
              maxLength="12"
              display="block"
              bordRadius="4px"
              onBlur={(e) => {
                setP2Name(e.target.value);
              }}
            />
          </StaticView>
          <StaticView>
            <Text text="Warna Pion" padding="16px" textAlign="center" />
            <StaticView display="flex" justifyContent="center">
              <Image
                className={`${
                  p2PionColor === "brown" ? "image-clicked" : "image-click"
                }`}
                src={"/icn/pion-brown.svg"}
                width="28px"
                margin="0 8px"
                cursor="pointer"
                onClick={() => {
                  clickSound.play();
                  setP2PionColor("brown");
                }}
              />
              <Image
                className={`${
                  p2PionColor === "gray" ? "image-clicked" : "image-click"
                }`}
                src={"/icn/pion-gray.svg"}
                width="28px"
                margin="0 8px"
                cursor="pointer"
                onClick={() => {
                  clickSound.play();
                  setP2PionColor("gray");
                }}
              />
              <Image
                className={`${
                  p2PionColor === "blue" ? "image-clicked" : "image-click"
                }`}
                src={"/icn/pion-blue.svg"}
                width="28px"
                margin="0 8px"
                cursor="pointer"
                onClick={() => {
                  clickSound.play();
                  setP2PionColor("blue");
                }}
              />
              <Image
                className={`${
                  p2PionColor === "green" ? "image-clicked" : "image-click"
                }`}
                src={"/icn/pion-green.svg"}
                width="28px"
                margin="0 8px"
                cursor="pointer"
                onClick={() => {
                  clickSound.play();
                  setP2PionColor("green");
                }}
              />
              <Image
                className={`${
                  p2PionColor === "light-brown"
                    ? "image-clicked"
                    : "image-click"
                }`}
                src={"/icn/pion-light-brown.svg"}
                width="28px"
                margin="0 8px"
                cursor="pointer"
                onClick={() => {
                  clickSound.play();
                  setP2PionColor("light-brown");
                }}
              />
            </StaticView>
          </StaticView>
        </StaticView>
        <StaticView margin="40px 0">
          <Text
            text="Tema Papan"
            fontWeight="bold"
            margin="0 0 16px 0"
            fontSize="20px"
            textAlign="center"
          />

          <StaticView>
            <StaticView display="flex" justifyContent="center">
              <Image
                className={`${
                  matchTheme === "batik1" ? "image-clicked" : "image-click"
                }`}
                src={"/img/match-theme-batik1.png"}
                width="80px"
                height="80px"
                margin="0 8px"
                cursor="pointer"
                boxShadow="0 0 2px 1px black"
                bordRadius="4px"
                onClick={() => {
                  clickSound.play();
                  setMatchTheme("batik1");
                }}
              />
              <Image
                className={`${
                  matchTheme === "batik2" ? "image-clicked" : "image-click"
                }`}
                src={"/img/match-theme-batik2.png"}
                width="80px"
                height="80px"
                margin="0 8px"
                cursor="pointer"
                boxShadow="0 0 2px 1px black"
                bordRadius="4px"
                onClick={() => {
                  clickSound.play();
                  setMatchTheme("batik2");
                }}
              />
            </StaticView>
          </StaticView>
        </StaticView>

  
        <SaveButton onClick={handleSave}>Simpan</SaveButton>
        <SaveNotification isVisible={isVisible} />
      </StaticView>

      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        rotate="90deg"
        transform="translate(200px, -30%)"
        top="-60px"
        right="-60px"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        bottom="-60px"
        left="-60px"
        rotate="-90deg"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
    </>
  );
}

export default CustomView;
