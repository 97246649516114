import React, { useEffect, useState } from "react";
import Image from "../../components/Image";
import AbsoluteView from "../../components/AbsoluteView";
import Button from "../../components/Button";
import Text from "../../components/Text";
import useSound from "../../hooks/useSound";
import { click } from "../../config";
import MatchSession from "../../Storage/sessionStorage/MatchSession";
import { useNavigate } from "react-router-dom";

function BoardView() {
  const navigate = useNavigate();
  const clickSound = useSound(click);

  const [board, setBoard] = useState(MatchSession.getBoard());

  useEffect(() => {
    MatchSession.setBoard(board).update();

  }, [board]);

  return (
    <>
      <AbsoluteView position="topcenter" padding="48px">
        <Text
          text="Pilih Papan"
          fontWeight="bold"
          width="max-content"
          fontSize="32px"
          textAlign="center"
          padding="48px"
        />
        <Image
          src={"/img/caturjawa-lock.png"}
          width="260px"
          display="block"
          margin="0 auto 15px auto"
        />
        <Button
          text="Catur Jawa"
          fontSize={"20px"}
          margin="0 auto 22px auto"
          height="max-content"
          filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
          width="212px"
          className={`btn btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset ${
            board === "caturJawa" ? "btn-clicked" : ""
          }`}
          onClick={() => {
            clickSound.play();
            setBoard("caturJawa");
          }}
        />
        <Image
          src={"/img/second-board.png"}
          display="block"
          width="180px"
          margin="0 auto 15px auto"
        />
        <Button
          text="Lelempengan"
          fontSize={"20px"}
          margin="0 auto 48px auto"
          height="max-content"
          filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
          width="212px"
          className={`btn btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset ${
            board === "damDaman" ? "btn-clicked" : ""
          }`}
          onClick={() => {
            clickSound.play();
            setBoard("damDaman");
          }}
        />
        <Button
          href={`/play/${MatchSession.getGameMode()}/${board}`}
          text="Lanjut"
          backgrColor="unset"
          color="#000000"
          margin="0 auto"
          fontSize="20px"
          textDecoration="underline"
          fontWeight="bold"
          className="btn btn-pseudo-hover btn-pseudo-active"
          disabled={!board}
          onClick={clickSound.play}
        />
      </AbsoluteView>
      <AbsoluteView position="topleft" padding="1.5rem">
        <Image
          src={"/icn/arrow-back.svg"}
          width="1.5rem"
          cursor="pointer"
          onClick={() => {
            clickSound.play();
            navigate(-1);
          }}
        />
      </AbsoluteView>

      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        rotate="90deg"
        transform="translate(200px, -30%)"
        top="-60px"
        right="-60px"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        bottom="-60px"
        left="-60px"
        rotate="-90deg"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
    </>
  );
}

export default BoardView;
