import React from "react";
import Image from "../../components/Image";
import AbsoluteView from "../../components/AbsoluteView";
import Button from "../../components/Button";
import Text from "../../components/Text";
import StaticView from "../../components/StaticView";
import useSound from "../../hooks/useSound";
import { click } from "../../config";
import { useNavigate } from "react-router-dom";

function SettingsView() {
  const clickSound = useSound(click);
  const navigate = useNavigate();

  return (
    <>
      <AbsoluteView position="topleft" padding="1.5rem">
        <Image
          src={"/icn/arrow-back.svg"}
          width="1.5rem"
          cursor="pointer"
          onClick={() => {
            clickSound.play();
            navigate(-1);
          }}
        />
      </AbsoluteView>

      <StaticView margin="100px auto 0 auto" width="max-content">
        <Text
          text="Pengaturan"
          margin="0 0 40px 0"
          textAlign="center"
          fontSize="32px"
          fontWeight="bold"
        />
        <Button
          href="/audio"
          text="Audio"
          width={"212px"}
          margin={"0 auto 20px auto"}
          fontSize={"20px"}
          filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
          onClick={clickSound.play}
          className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
        />
        <Button
          href="/tutorial"
          text="Tutorial"
          width={"212px"}
          margin={"0 auto 20px auto"}
          fontSize={"20px"}
          filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
          onClick={clickSound.play}
          className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
        />
      </StaticView>

      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        rotate="90deg"
        transform="translate(200px, -30%)"
        top="-60px"
        right="-60px"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        bottom="-60px"
        left="-60px"
        rotate="-90deg"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
    </>
  );
}

export default SettingsView;
