import { victoryPoint } from "../config";

const checkWinnings = function (playerPionsCoord) {
  try {
    // Destructure positions into individual pion coordinates
    const [{ x: pX1, y: pY1 }, { x: pX2, y: pY2 }, { x: pX3, y: pY3 }] =
      playerPionsCoord;

    // Check if any victory point matches the pion positions
    return victoryPoint.some(
      (vP) =>
        pX1 === vP.pX1 &&
        pY1 === vP.pY1 &&
        pX2 === vP.pX2 &&
        pY2 === vP.pY2 &&
        pX3 === vP.pX3 &&
        pY3 === vP.pY3
    );
  } catch (error) {
    console.error(error);
  }
};

export default checkWinnings;
