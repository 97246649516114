import React from "react";
import AbsoluteView from "../../components/AbsoluteView";
import Text from "../../components/Text";

function ComingSoonView() {
  return (
    <>
      <AbsoluteView position="middlecenter" width="max-content">
        <Text text="Coming Soon!" />
      </AbsoluteView>
    </>
  );
}

export default ComingSoonView;
