/**
 * Component to create a player name
 * @param {object} style - style of the player name
 * @returns Html elements
 * @example
 * <PlayerName
 *  className = "value"
 *  text = "value"
 *  width = "value"
 *  height = "value"
 *  padding = "value value value value" // Ex: "2px 4px 2px 4px"
 *  margin = "value value value value"  // Ex: "2px 4px 2px 4px"
 *  color = "value"
 *  backgrColor = "value"
 *  display = "value"
 *  fontSize = "value"
 *  boxShadow = "valueX valueY valueBlur valueSpread valueColor" // Ex: "2px 0 px 0 black inset"
 *  textShadow = "valueX valueY valueBlur valueSpread valueColor" // Ex: "2px 0 px 0 black inset"
 *  fontFamily = "value"
 *  textAlign = "value"
 *  opacity = "value"
 *  animation = "value"
 * />
 */
function PlayerName({
  text = "Hello World!",
  player,
  playerTurn,
}) {
  return (
    <p
      style={{
        padding: "48px",
        fontSize: "20px",
        textAlign: "center",
        animation : `${playerTurn === player ? "blink 1.5s infinite" : ""}`
      }}
    >
      {text}
    </p>
  );
}

export default PlayerName;
