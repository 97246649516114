import React from "react";
import Image from "../../components/Image";
import AbsoluteView from "../../components/AbsoluteView";
import Text from "../../components/Text";
import StaticView from "../../components/StaticView";
import useSound from "../../hooks/useSound";
import { click } from "../../config";
import { useNavigate } from "react-router-dom";
import Iframe from "../../components/Iframe";

function TutorialView() {
  const clickSound = useSound(click);
  const navigate = useNavigate();

  return (
    <>
      <AbsoluteView position="topleft" padding="1.5rem">
        <Image
          src={"/icn/arrow-back.svg"}
          width="1.5rem"
          cursor="pointer"
          onClick={() => {
            clickSound.play();
            navigate(-1);
          }}
        />
      </AbsoluteView>

      <Text
        text="Tutorial"
        margin="80px 0 80px 0"
        textAlign="center"
        fontSize="32px"
        fontWeight="bold"
      />

      <StaticView>
        <StaticView
          margin="0 auto 40px auto"
          // width="300px"
          // backgrColor="#ECB801"
          // border="12px solid #ECB801"
        >
          <Text
            text="Sesuaikan"
            margin = "0 0 12px 0"
            textAlign="center"
            fontSize="20px"
            fontWeight="bold"
          />
          <Iframe
            bordRadius="8px"
            width="300px"
            height="180px"
            backgrColor="#ECB801"
            margin= "0 auto"
            border="8px solid #ECB801"
            display= "block"
            src={
              "https://www.youtube.com/embed/bAl111p0NXA?si=cKhQIw7KuH2xzeDv"
            }
          />
        </StaticView>
        <StaticView
          margin="0 auto 40px auto"
          // width="300px"
          // backgrColor="#ECB801"
          // border="12px solid #ECB801"
        >
          <Text
            text="Main Sendiri"
            margin = "0 0 12px 0"
            textAlign="center"
            fontSize="20px"
            fontWeight="bold"
          />
          <Iframe
            bordRadius="8px"
            width="300px"
            height="180px"
            backgrColor="#ECB801"
            margin= "0 auto"
            border="8px solid #ECB801"
            display= "block"
            src={
              "https://www.youtube.com/embed/chD7CWtGjhI?si=nH_YB4PCc6dCFhHT"
            }
          />
        </StaticView>
        <StaticView
          margin="0 auto 40px auto"
          // width="300px"
          // backgrColor="#ECB801"
          // border="12px solid #ECB801"
        >
          <Text
            text="Main Bareng"
            margin = "0 0 12px 0"
            textAlign="center"
            fontSize="20px"
            fontWeight="bold"
          />
          <Iframe
            bordRadius="8px"
            width="300px"
            height="180px"
            backgrColor="#ECB801"
            margin= "0 auto"
            border="8px solid #ECB801"
            display= "block"
            src={
              "https://www.youtube.com/embed/K1Basm1tqV0?si=O0kNiiKOmyInMu0v"
            }
          />
        </StaticView>
      </StaticView>

      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        rotate="90deg"
        transform="translate(200px, -30%)"
        top="-60px"
        right="-60px"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        bottom="-60px"
        left="-60px"
        rotate="-90deg"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
    </>
  );
}

export default TutorialView;
