import { useEffect, useState } from "react";
import AppLocalStorage from "../Storage/localStorage/AppLocalStorage";

const useSound = (url) => {
  const [audio] = useState(new Audio(url));
  const [volume] = useState(AppLocalStorage.getVolume());
  const [muted, setMuted] = useState(AppLocalStorage.getMuted());


  useEffect(() => {
      if(audio){
          audio.volume = muted ? 0 : volume;
      }
  }, [volume, muted]);

//   useEffect(() =>{
//     return () =>{
//         audio.pause();
//         audio.src = "";
//       }
//   }, [url])
  useEffect(() => {
      AppLocalStorage.setMuted(muted).update();
  }, [muted]);




  const play = () => {
    audio.currentTime = 0;
    audio.play().catch(error => console.error("Audio play failed", error));
  };
  const mute = (muted = true) => {
    setMuted(muted);
  };

  const clear = function(){
    audio.pause();
    audio.src = ""; 
  }

  return { play, mute, muted, clear };
};

export default useSound;
