/**
 * Extracts transform values from a CSS transform string.
 *
 * This function takes a CSS transform string containing the `translate(xpx, ypx)` values
 * and returns the `x` and `y` values as an object. If the string does not contain a
 * `translate` transformation, the function returns `null`.
 *
 * @param {string} str - The string containing the CSS transform.
 * @returns {Object|null} An object containing the `x` and `y` values as numbers, or `null` if there is no match.
 *
 * @example
 * const transformStr = "translate(50px, 100px)";
 * const result = extractTransformValues(transformStr);
 * console.log(result); // { x: 50, y: 100 }
 */
export const extractTransformValues = function (str) {
    // Regex to capture numbers in the format translate(xpx, ypx)
    const regex = /[0-9]+/g;
    const matches = str.match(regex);
  
    // Ensure there is a match and return the values as numbers
    if (matches) {
      return {
        x: parseInt(matches[0], 10),
        y: parseInt(matches[1], 10),
      };
    } else {
      return null; // If there is no match
    }
  };