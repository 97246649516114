/**
 * Component to create a scoreboard
 * @param {object} style - style of the scoreboard
 * @returns Html elements
 * @example
 * <Scoreboard
 *  scoreP1 = "value"
 *  scoreP2 = "value"
 *  display = "value"
 *  margin = "value"
 *  scoreLineHeight = "value"
 *  rotate = "value"
 * />
 */

import styled from "styled-components";

const ScoreboardContainer = styled.div`
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: -48px;
  display: ${(props) => (props.$round === 1 ? "none" : "flex")};
`;
function Scoreboard({ scoreP1, scoreP2, round }) {
  return (
    <ScoreboardContainer $round={round}>
      <p style={{ fontSize: "20px" }}>{scoreP2}</p>
      <img src="/icn/icon-double-dots.svg" style={{ margin: "12px 0" }}></img>
      <p style={{ fontSize: "20px" }}>{scoreP1}</p>
    </ScoreboardContainer>
  );
}

export default Scoreboard;
