/**
 * Executes actions before and after a timeout.
 *
 * @param {Function} [actionBefore=_=>{}] - The action to execute before the timeout.
 * @param {Function} [actionAfter=_=>{}] - The action to execute after the timeout.
 * @param {number} time - The time in milliseconds to wait before executing `actionAfter`.
 *
 * @example
 * // Execute console.log before and after 2 seconds
 * setTimeoutWithCallback(
 *   () => console.log('Before timeout'),
 *   () => console.log('After timeout'),
 *   2000
 * );
 *
 * // Execute only after 1 second
 * setTimeoutWithCallback(undefined, () => console.log('After timeout'), 1000);
 */
export const executeWithDelay = function (
    actionBefore = (_) => {},
    actionAfter = (_) => {},
    time
  ) {
    actionBefore();
    setTimeout(actionAfter, time);
  };