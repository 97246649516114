const Iframe = ({
  src,
  connect,
  onClick,
  className,
  width,
  height,
  padding,
  margin,
  backgrColor,
  bordRadius,
  border,
  frameBorder,
  display
}) => {
  return (
    <iframe
      src={src}
      style={{
        width: width,
        height: height,
        borderRadius : bordRadius,
        backgroundColor: backgrColor, 
        border: border,
        margin : margin,
        display : display
        
      }}
      frameBorder={frameBorder}
      allowfullscreen
    ></iframe>
  );
};

export default Iframe;
