const MatchTheme = ({ url }) =>{
    return (
        <div style={{
            top : 0,
            left : 0,
            width : "100%",
            height : "100%",
            position: "absolute",
            backgroundRepeat : "no-repeat",
            backgroundSize : "100%",
            opacity : "0.15",
            backgroundImage : `url(${url})`
        }}>
        </div>
    )
}
  
  export default MatchTheme;
  