import React, { useEffect, useState } from "react";
import Image from "../../components/Image";
import AbsoluteView from "../../components/AbsoluteView";
import Button from "../../components/Button";
import Text from "../../components/Text";
import StaticView from "../../components/StaticView";
import useSound from "../../hooks/useSound";
import { click, ROUND_FIVE, ROUND_ONE, ROUND_THREE } from "../../config";
import MatchSession from "../../Storage/sessionStorage/MatchSession";
import { useNavigate } from "react-router-dom";

function RoundView() {
  const navigate = useNavigate();
  const clickSound = useSound(click);

  const [round, setRound] = useState(MatchSession.getRound());

  useEffect(() => {
    MatchSession.setRound(round).update();
  }, [round]);

  return (
    <>
      <AbsoluteView position="middlecenter">
        <Text
          text="Ronde"
          textAlign="center"
          fontSize="32px"
          fontWeight="bold"
          margin="0 0 70px 0"
        />

        <StaticView
          display="flex"
          justifyContent="center"
          width="100%"
          flexDirection="column"
          maxWidth="500px"
          margin="0 auto"
        >
          <Button
            text="1 Ronde"
            fontSize={"20px"}
            height="max-content"
            filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
            width="212px"
            margin="0 0 24px 0"
            className={`btn btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset ${
              round === ROUND_ONE ? "btn-clicked" : ""
            }`}
            onClick={() => {
              setRound(ROUND_ONE);

              clickSound.play();
            }}
          />
          <Button
            text="3 Ronde"
            width="212px"
            fontSize={"20px"}
            height="max-content"
            filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
            className={`btn btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset ${
              round === ROUND_THREE ? "btn-clicked" : ""
            }`}
            onClick={() => {
              setRound(ROUND_THREE);

              clickSound.play();
            }}
          />
          <Button
            text="5 Ronde"
            margin="24px 0 70px 0"
            fontSize={"20px"}
            filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
            width="212px"
            height="max-content"
            className={`btn btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset ${
              round === ROUND_FIVE ? "btn-clicked" : ""
            }`}
            onClick={() => {
              setRound(ROUND_FIVE);

              clickSound.play();
            }}
          />
        </StaticView>

        <StaticView>
          <Button
            href="/board"
            text="Lanjut"
            backgrColor="unset"
            color="#000000"
            margin="0 auto"
            fontSize="20px"
            fontWeight="bold"
            textDecoration="underline"
            className="btn btn-pseudo-hover btn-pseudo-active"
              onClick={clickSound.play}
            disabled={!round}
          />
        </StaticView>
      </AbsoluteView>

      <AbsoluteView position="topleft" padding="1.5rem">
        <Image
          src={"icn/arrow-back.svg"}
          width="1.5rem"
          cursor="pointer"
          onClick={() => {
            clickSound.play();
            MatchSession.clear().update();
            navigate(-1);
          }}
        />
      </AbsoluteView>

      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        rotate="90deg"
        transform="translate(200px, -30%)"
        top="-60px"
        right="-60px"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        bottom="-60px"
        left="-60px"
        rotate="-90deg"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
    </>
  );
}

export default RoundView;
