import React from "react";
import Image from "../../components/Image";
import AbsoluteView from "../../components/AbsoluteView";
import Button from "../../components/Button";
import Text from "../../components/Text";
import StaticView from "../../components/StaticView";
import useSound from "../../hooks/useSound";
import { click } from "../../config";
import MatchSession from "../../Storage/sessionStorage/MatchSession";

function HomeView() {
  const clickSound = useSound(click);

  const switchSound = function(){
    const muted = clickSound.muted;

    clickSound.play();

    clickSound.mute(!muted);
  }
  return <>
    <AbsoluteView position="middlecenter" width="max-content">
      <StaticView margin="10px 0 64px 0">
        <Text
          text="Catur Jawa"
          fontSize="48px"
          fontWeight="bold"
          textAlign="center"
          margin="0 0 10px 0"
        />
        <Text
          text="Budaya Kita"
          fontWeight="bold"
          fontFamily="Javacom"
          fontSize="24px"
          textAlign="center"
        />
      </StaticView>

      <StaticView
        display="flex"
        flexDirection="column"
        width="max-content"
        margin="0 auto"
      >

        <Button
          href="/round"
          text="Main Sendiri"
          width={"212px"}
          margin={"0 auto 20px auto"}
          fontSize={"20px"}
          filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
          onClick={() =>{
            clickSound.play();
            MatchSession.setGameMode("vsai").update();
          }}
          // icon = {icn}
          className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
        />
        <Button
          href="/gamemode"
          text="Main Bareng"
          width={"212px"}
          margin={"0 auto 20px auto"}
          fontSize={"20px"}
          filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
          onClick={clickSound.play}
          className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
        />
        {/* <Button
              href="/turnamen"
              text="Turnamen"
              width={"212px"}
              margin={"0 auto 20px auto"}
              fontSize={"20px"}
              filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
              onClick={() => RoomAudio.getAudio().mouseClick.play()}
              className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
            /> */}
        <Button
          href="/custom"
          text="Sesuaikan"
          width={"212px"}
          margin={"0 auto 20px auto"}
          fontSize={"20px"}
          filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
          onClick={clickSound.play}
          className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
        />
        <Button
          href="/settings"
          text="Pengaturan"
          width={"212px"}
          margin={"0 auto 20px auto"}
          fontSize={"20px"}
          filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
          onClick={clickSound.play}
          className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
        />
      </StaticView>
    </AbsoluteView>
    <AbsoluteView position="bottomleft" padding="0 0 1.5rem 1.5rem">
      <Image
        src= {`/icn/icon-sound-${!clickSound.muted?"enabled":"disabled"}.svg`}
        width="2.5rem"
        height="2.5rem"
        cursor="pointer"
        onClick={switchSound}
      />
    </AbsoluteView>

    <AbsoluteView position="topright">
      <Image
        src={"/img/circle-batik.png"}
        position="fixed"
        width="200px"
        right="-60px"
        bottom="-60px"
        rotate="90deg"
        animation="rotate 15s linear infinite"
      />
    </AbsoluteView>
    <Image
      src={"/img/circle-batik.png"}
      position="fixed"
      left="-60px"
      top="-60px"
      width="200px"
      animation="rotate 15s linear infinite"
    />
  </>;
}

export default HomeView;
