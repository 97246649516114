import { useNavigate } from "react-router-dom";
import AbsoluteView from "../../components/AbsoluteView";
import Image from "../../components/Image";
import StaticView from "../../components/StaticView";
import Text from "../../components/Text";
import { executeWithDelay } from "../../utils/executeWithDelay";
import { TIME_LONG } from "../../config";
// import logo from "./logo192.png";
function Splashscreen(){
    const navigate = useNavigate();
    executeWithDelay((_) => {}, () =>{navigate("home", {replace:true})}, TIME_LONG);
    return <>
        <AbsoluteView position="middlecenter" padding="0 0 99px 0">
          <Image
            margin="0 auto"
            display="block"
            backgrColor="black"
            animation="2s fade linear"
            src={"/img/logoGame.jpg"}
            width="12.5rem"
          />
          <StaticView margin="20px auto 10px auto" width="max-content">
            <Text
              display="inline"
              text="C"
              opacity="0"
              fontSize = "20px"
              animation="fade 0s 2s linear forwards"
              fontFamily="Javacom"
            />
            <Text
              display="inline"
              text="a"
              opacity="0"
              fontSize = "20px"
              animation="fade 0.5s 2s linear forwards"
              fontFamily="Javacom"
            />
            <Text
              display="inline"
              text="t"
              opacity="0"
              fontSize = "20px"
              animation="fade 1s 2s linear forwards"
              fontFamily="Javacom"
            />
            <Text
              display="inline"
              text="u"
              opacity="0"
              fontSize = "20px"
              animation="fade 1.5s 2s linear forwards"
              fontFamily="Javacom"
            />
            <Text
              display="inline"
              text="r"
              opacity="0"
              fontSize = "20px"
              animation="fade 2s 2s linear forwards"
              fontFamily="Javacom"
            />
            <Text
              display="inline"
              text=" "
              opacity="0"
              fontSize = "20px"
              animation="fade 2.5s 2s linear forwards"
              fontFamily="Javacom"
            />
            <Text
              display="inline"
              text="J"
              opacity="0"
              fontSize = "20px"
              animation="fade 3s 2s linear forwards"
              fontFamily="Javacom"
            />
            <Text
              display="inline"
              text="a"
              opacity="0"
              fontSize = "20px"
              animation="fade 3.5s 2s linear forwards"
              fontFamily="Javacom"
            />
            <Text
              display="inline"
              text="w"
              opacity="0"
              fontSize = "20px"
              animation="fade 4s 2s linear forwards"
              fontFamily="Javacom"
            />
            <Text
              display="inline"
              text="a"
              opacity="0"
              fontSize = "20px"
              animation="fade 4.5s 2s linear forwards"
              fontFamily="Javacom"
            />
          </StaticView>
          <Text
            text="Indonesia Traditional Games"
            textAlign="center"
            fontSize="16px"
            animation="fade 2s 4.5s linear forwards"
            opacity="0"
          />
        </AbsoluteView>
          <AbsoluteView position="bottomcenter" bottom="20px">
          <Text
            text="made by galabor team"
            textAlign="center"
            fontSize="16px"
            animation="fade 2s 4.5s linear forwards"
            opacity="0"
          /> 
          </AbsoluteView>
      </>
}

export default Splashscreen;