import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeView from './page/home/HomeView';
import Splashscreen from './page/splashscreen/Splashscreen';
import './styles/css/main.scss'
import RoundView from './page/round/RoundView';
import BoardView from './page/board/BoardView';
import DamDamamPlayView from './page/play/DamDamanPlayView';
import CustomView from './page/custom/CustomView';
import GameModeView from './page/gameMode/GameModeView';
import SettingsView from './page/settings/SettingsView';
import AudioView from './page/audio/AudioView';
import TutorialView from './page/tutorial/TutorialView';
import ComingSoonView from './page/comingSoonView/ComingSoon.View';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Splashscreen />} />
        <Route path="/home" element={<HomeView />} />
        <Route path="/round" element={<RoundView />} />
        <Route path="/board" element={<BoardView />} />
        <Route path="/custom" element={<CustomView />} />
        <Route path="/gameMode" element={<GameModeView />} />
        <Route path="/settings" element={<SettingsView />} />
        <Route path="/audio" element={<AudioView />} />
        <Route path="/tutorial" element={<TutorialView />} />
        <Route path="/play/vsai/damDaman" element={<DamDamamPlayView />} />
        <Route path="/play/vsai/caturJawa" element={<ComingSoonView />} />
        <Route path="/play/offline/damDaman" element={<DamDamamPlayView />} />
        <Route path="/play/offline/caturJawa" element={<ComingSoonView />} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;