import React from "react";
import Image from "../../components/Image";
import AbsoluteView from "../../components/AbsoluteView";
import Button from "../../components/Button";
import Text from "../../components/Text";
import useSound from "../../hooks/useSound";
import { click } from "../../config";
import MatchSession from "../../Storage/sessionStorage/MatchSession";
import { useNavigate } from "react-router-dom";

function GameModeView() {
  const clickSound = useSound(click);
  const navigate = useNavigate();

  return (
    <>
      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        rotate="90deg"
        transform="translate(200px, -30%)"
        top="-60px"
        right="-60px"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        bottom="-60px"
        left="-60px"
        rotate="-90deg"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
      <AbsoluteView position="topleft" padding="1.5rem">
        <Image
          src={"/icn/arrow-back.svg"}
          width="1.5rem"
          cursor="pointer"
          onClick={()=>{
            clickSound.play();
            navigate(-1);
          }}
        />
      </AbsoluteView>

      <AbsoluteView position="middlecenter">
        <Text
          text="Pilih Mode"
          textAlign="center"
          fontSize="32px"
          fontWeight="bold"
          margin="0 0 60px 0"
        />
        <Button
          href="/round"
          width="212px"
          text="Offline"
          margin={"0 auto 30px auto"}
          fontSize={"20px"}
          filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
          onClick={() => {
            clickSound.play();
            MatchSession.setGameMode("offline").update();
          }}
          className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
        />
        {/* <Button
            href="/play/online"
            width="212px"
            text="Online"
            margin={"0 auto 20px auto"}
            fontSize={"14px"}
            filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
            onClick={this._setGameMode.bind(this, "online")}
            className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
          /> */}
      </AbsoluteView>
    </>
  );
}

export default GameModeView;
