const DamDamanBoard = ({
  id,
  className,
  children,
  width = "300px",
  height = "300px",
  backgrColor,
  padding,
  margin,
  border = "2px solid black",
  position = "relative",
}) => {
  return (
    <div
      id={id}
      className={`board ${className}`}
      style={{
        width: width,
        height: height,
        backgroundColor: backgrColor,
        backgroundImage: "url(/img/lelempenganBoard.svg)",
        backgroundSize : "100%",
        backgroundRepeat: "no-repeat",
        padding: padding,
        margin: margin,
        position: position,
      }}
    >
      {children}
    </div>
  );
};

export default DamDamanBoard;

//   {/* Line Top */}
//   <hr
//   style={{
//     border: border,
//     position: "absolute",
//     width: "100%",
//     top: 0,
//   }}
// ></hr>
// {/* Line Right */}
// <hr
//   style={{
//     border: border,
//     position: "absolute",
//     width: "100%",
//     transformOrigin: "right",
//     rotate: "-90deg",
//   }}
// ></hr>
// {/* Line Bottom */}
// <hr
//   style={{
//     border: border,
//     position: "absolute",
//     width: "100%",
//     transform: `translate(0, ${height})`
//   }}
// ></hr>
// {/* Line left */}
// <hr
//   style={{
//     border: border,
//     position: "absolute",
//     width: "100%",
//     transformOrigin: "left",
//     rotate: "90deg",
//   }}
// ></hr>
// {/* Line diagonal */}
// <hr
//   style={{
//     border: border,
//     transformOrigin: "left",
//     rotate: "45deg",
//     position: "absolute",
//     width: `${Math.sqrt(2) * parseInt(height)}px`,
//     background: "black",
//   }}
// ></hr>
// {/* Line diagonal */}
// <hr
//   style={{
//     border: border,
//     position: "absolute",
//     width: `${Math.sqrt(2) * parseInt(height)}px`,
//     transformOrigin: "left",
//     transform: `translate(0, ${height}) rotate(-45deg)`,
//     background: "black",
//   }}
// ></hr>
// {/* Line middle */}
// <hr
//   style={{
//     border: border,
//     position: "absolute",
//     width: "100%",
//     transform: `translate(0, ${parseInt(height)/2}px)`,
//     transformOrigin: "left",
//   }}
// ></hr>
// {/* Line middle */}
// <hr
//   style={{
//     border: border,
//     position: "absolute",
//     width: "100%",
//     rotate: "90deg",
//     transform: `translate(${parseInt(height)/2}px, 0)`,
//   }}
// ></hr>
// {children}
