/**
 * Component to create a image
 * @param {object} style - style of the image
 * @returns Html elements
 * @example
 * <Image
 *  src = "value"
 *  onClick = "callback"
 *  className = "value"
 *  width = "value"
 *  height = "value"
 *  padding = "value value value value" // Ex: "2px 4px 2px 4px"
 *  margin = "value value value value"  // Ex: "2px 4px 2px 4px"
 *  backgrColor = "value"
 *  backgrImage = "value"
 *  backgrRepeat = "value"
 *  backgrSize = "value"
 *  bordRadius = "value"
 *  display = "value"
 *  fontSize = "value"
 *  boxShadow = "valueX valueY valueBlur valueSpread valueColor valueShadow" // Ex: "2px 0 px 0 black inset"
 *  opacity= "value"
 *  rotate = "value"
 *  cursor = "value"
 *  animation = "value"
 *  position = "value"
 *  top = "value"
 *  right = "value"
 *  bottom = "value"
 *  left = "value"
 *  border = "value"
 *  zIndex = "value"
 * />
 */

const Image = ({
  src,
  connect,
  onClick,
  className,
  width,
  height,
  padding,
  margin,
  backgrColor,
  backgrRepeat,
  backgrSize,
  backgrImage,
  bordRadius,
  display,
  boxShadow,
  opacity,
  rotate,
  cursor,
  animation,
  position,
  top,
  right,
  bottom,
  left,
  border,
  zIndex,
  transform
}) => {
  return (
    <img
      className={className}
      src={src}
      onClick={onClick}
      connect = {connect}
      style={{
        width: width,
        height: height,
        padding: padding,
        margin: margin,
        backgroundColor: backgrColor,
        backgroundImage: backgrImage,
        backgroundRepeat: backgrRepeat,
        backgroundSize: backgrSize,
        borderRadius: bordRadius,
        display: display,
        boxShadow: boxShadow,
        rotate: rotate,
        opacity: opacity,
        cursor: cursor,
        animation: animation,
        position: position,
        top: top,
        right: right,
        bottom: bottom,
        left: left,
        border:border,
        zIndex:zIndex,
        transform: transform
      }}
    ></img>
  );
};

export default Image;
