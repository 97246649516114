import { MATCH_SESSION_KEY } from "../../config";

class MatchSession {
  _matchSession;
  constructor() {
    this._createSessionPref();

    this._matchSession = JSON.parse(sessionStorage.getItem(MATCH_SESSION_KEY));
  }
  update() {
    sessionStorage.setItem(
      MATCH_SESSION_KEY,
      JSON.stringify(this._matchSession)
    );
  }
  setRound(round) {
    this._matchSession.round = round;
    return this;
  }
  setBoard(board) {
    this._matchSession.board = board;
    return this;
  }
  getRound() {
    return this._matchSession.round;
  }
  getBoard() {
    return this._matchSession.board;
  }
  setScore(playerTurn, score) {
    if(playerTurn === "p1") this._matchSession.scoreP1 = score;
    if(playerTurn === "p2") this._matchSession.scoreP2 = score;
    
    return this;
  }
  setScoreP2(score) {
    this._matchSession.scoreP2 = score;
    return this;
  }
  getScore(playerTurn) {
    if(playerTurn === "p1") return this._matchSession.scoreP1;
    if(playerTurn === "p2") return this._matchSession.scoreP2;
  }
  getScoreP2() {
    return this._matchSession.scoreP2;
  }
  setGameMode(mode) {
    this._matchSession.mode = mode;
    return this;
  }
  getGameMode() {
    return this._matchSession.mode;
  }
  _createSessionPref() {
    if (sessionStorage.getItem(MATCH_SESSION_KEY)) return;

    sessionStorage.setItem(
      MATCH_SESSION_KEY,
      JSON.stringify({
        round: null,
        board: null,
        scoreP1: 0,
        scoreP2: 0,
        mode : ""

      })
    );
  }
  clear() {
    this._matchSession.round = null;
    this._matchSession.board = null;
    this._matchSession.scoreP1 = 0;
    this._matchSession.scoreP2 = 0;
    this._matchSession.mode = "";

    return this;
  }
}

export default new MatchSession();
