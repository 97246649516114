import React from "react";
import styled, { keyframes } from "styled-components";

// Animasi untuk pesan
const fadeIn = keyframes`
  0% { opacity: 0; transform: scale(0.9); }
  100% { opacity: 1; transform: scale(1); }
`;

const fadeOut = keyframes`
  0% { opacity: 1; transform: scale(1); }
  100% { opacity: 0; transform: scale(0.9); }
`;

const NotificationContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #ecb801;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  animation: ${fadeIn} 0.5s forwards;
  z-index: 1000;
`;

const Icon = styled.div`
  margin-right: 10px;
  font-size: 1.5em;
`;

const SaveButton = styled.button`
  background-color: #ECB801;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))";
  width :212px;
  margin: 0 auto 20px auto;
  transition: background-color 0.3s;
  box-shadow: 0px 0px 4px 1px black inset;
  &:hover {
    background-color: #BC9300;
  }
    &:active{
    background-color: #BC9300;
    transform: scale(0.95); /* Menyusutkan tombol sebanyak 5% */
    }
`;

const SaveNotification = ({ isVisible }) =>
  isVisible && (
    <NotificationContainer>
      <Icon>✔️</Icon>
      <span>Berhasil Disimpan!</span>
    </NotificationContainer>
  );

export { SaveButton, SaveNotification };
