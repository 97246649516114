/**
 * Component to created a MotionPoint
 * @param {object} style - style of the AbsoluteView
 * @returns html Element
 * @example
 * <MotionPoint
 *  player = "value"
 *  className = "value"
 *  onClick = "value"
 *  width = "value"
 *  height = "value"
 *  backgColor = "value"
 *  zIndex = "value"
 *  position = "value"
 *  boxShadow = "value"
 *  transform = "value"
 *  borderRadius = "value"
 *  cursor = "value"
 * <MotionPoint>
 */

const MotionPoint = ({
    player,
    className,
    onClick,
    width,
    height,
    backgrColor,
    zIndex = 10,
    position = "absolute",
    boxShadow,
    transform,
    borderRadius = "100%",
    cursor = "pointer",
    ref
  }) => {
    return (
      <img
        player= {player}
        className={`motion-point ${className}`}
        onClick={onClick}
        style={{
          width: width,
          height: height,
          backgroundColor :backgrColor,
          zIndex: zIndex,
          position: position,
          top: `-${parseInt(width)/2}px`,
          left: `-${parseInt(width)/2}px`,
          boxShadow : boxShadow,
          transform: transform,
          borderRadius: borderRadius,
          cursor:cursor,
          ref : ref
        }}
      ></img>
    );
  };
  
  export default MotionPoint;
  