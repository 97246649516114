
export const pionCanMove = function(pionCoord, mPCoord) {
    const { x: pX, y: pY } = pionCoord;

    const { x: mpX, y: mpY } = mPCoord;
    
    if (Math.abs(pX - mpX) <= 150 && Math.abs(pY - mpY) <= 150) {
      if (pX === 150 && pY === 0 && mpX === 0 && mpY === 150) return;
      if (pX === 150 && pY === 0 && mpX === 300 && mpY === 150) return;

      if (pX === 300 && pY === 150 && mpX === 150 && mpY === 0) return;
      if (pX === 300 && pY === 150 && mpX === 150 && mpY === 300) return;

      if (pX === 150 && pY === 300 && mpX === 0 && mpY === 150) return;
      if (pX === 150 && pY === 300 && mpX === 300 && mpY === 150) return;

      if (pX === 0 && pY === 150 && mpX === 150 && mpY === 0) return;
      if (pX === 0 && pY === 150 && mpX === 150 && mpY === 300) return;

      return true;
    }
    return false;
  }