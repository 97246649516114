/**
 * Component to created a Pion
 * @param {object} style - style of the AbsoluteView
 * @returns html Element
 * @example
 * <Pion
 *  src = "value"
 *  player = "value"
 *  className = "value"
 *  onClick = "value"
 *  width = "value"
 *  padding = "value value value value" // Ex: "2px 4px 2px 4px"
 *  margin = "value value value value"  // Ex: "2px 4px 2px 4px"
 *  zIndex = "value"
 *  position = "value"
 *  boxShadow = "value"
 *  transform = "value"
 *  borderRadius = "value"
 * <Pion>
 */

const Pion = ({
  src,
  player,
  className = "",
  onClick,
  width,
  padding,
  margin,
  zIndex = 10,
  position = "absolute",
  boxShadow,
  transform,
  borderRadius = "100%",
  cursor="pointer",
  height
}) => {
  return (
    <img
      src={src}
      player= {player}
      className={`pion ${className}`}
      onClick={onClick}
      style={{
        width: width?width:height,
        padding: padding,
        margin: margin,
        zIndex: zIndex,
        position: position,
        top: `-${parseInt(width)/2}px`,
        left: `-${parseInt(width)/2}px`,
        boxShadow : boxShadow,
        transform: transform,
        borderRadius: borderRadius,
        cursor: cursor
      }}
    ></img>
  );
};

export default Pion;
