import { APP_LOCAL_KEY } from "../../config";

class AppLocalStorage {
  _appLocalStorage;
  constructor() {
    this._createSessionPref();
    
    this._appLocalStorage = JSON.parse(localStorage.getItem(APP_LOCAL_KEY));
  }
  update() {
    localStorage.setItem(
      APP_LOCAL_KEY,
      JSON.stringify(this._appLocalStorage)
    );
  }
  setVolume(volume) {
    this._appLocalStorage.volume = volume;
    return this;
  }
  getVolume() {
    return this._appLocalStorage.volume;
  }
  setMuted(muted) {
    this._appLocalStorage.muted = muted;
    return this;
  }
  getMuted() {
    return this._appLocalStorage.muted;
  }
  _createSessionPref() {
    if (localStorage.getItem(APP_LOCAL_KEY)) return;

    localStorage.setItem(
      APP_LOCAL_KEY,
      JSON.stringify({
        volume : 1,
        muted : false,
      })
    );
  }
  clear() {

    return this;
  }
}

export default new AppLocalStorage();
