import checkWinnings from "../utils/checkWinnings";
import { extractTransformValues } from "../utils/extractTransformValues";
import { pionCanMove } from "../utils/pionCanMove";

const InferenceSystem = () => {
  const findWinningCoords = function (playerPionCoords, mPCoords) {
    let pointOfWin = {
      playerPionCoord: -1,
      winningCoord: -1,
    };
  
    playerPionCoords.forEach((playerPionCoord, i) => {
      mPCoords.forEach((mPCoord) => {
        const playerPionCoordsCopy = playerPionCoords.map(
          (playerPionCoord) => playerPionCoord
        );
  
        playerPionCoordsCopy[i] = mPCoord;
  
        if (
          checkWinnings(
            playerPionCoordsCopy.sort((a, b) => a.x - b.x || a.y - b.y)
          )
        ) {
          if (pionCanMove(playerPionCoord, mPCoord)) {
          //   console.log(playerPionCoord, mPCoord);
            pointOfWin = {
              playerPionCoord: playerPionCoord,
              winningCoord: mPCoord,
            };
          }
        }
      });
    });
    return pointOfWin;
  };
  
  const getRandCoord = function (p2PionCoords, mPCoords) {
    const randP2PionCoord = p2PionCoords[Math.floor(Math.random() * 3)];
    const randMpCoord = mPCoords[Math.floor(Math.random() * 3)];
      
    return { randPionCoord: randP2PionCoord, randCoord: randMpCoord };
  }
  
  const findElementByCoord = (coord, elements) =>
    elements.find(({ style }) => {
      const { x, y } = extractTransformValues(style.transform);
      return x === coord.x && y === coord.y;
    });

  const getNextMove = function (board) {
    const mPCoords = [...board.querySelectorAll(".motion-point")].map(
      (mPElement) => {
        return extractTransformValues(mPElement.style.transform);
      }
    );
    const p1PionCoords = [...board.querySelectorAll("[player=p1]")].map(
      (playerPionElement) => {
        return extractTransformValues(playerPionElement.style.transform);
      }
    );
    const p2PionCoords = [...board.querySelectorAll("[player=p2]")].map(
      (playerPionElement) => {
        return extractTransformValues(playerPionElement.style.transform);
      }
    );

    //Attack, IF player 2 has victory points, then move to victory points
    const { playerPionCoord: p2PionCoord, winningCoord: p2WinningCoord } =
      findWinningCoords(p2PionCoords, mPCoords);

    if (pionCanMove(p2PionCoord, p2WinningCoord)) {
      const motionPointEl = findElementByCoord(p2WinningCoord, [
        ...board.querySelectorAll(".motion-point"),
      ]);
      const p2PionEl = findElementByCoord(p2PionCoord, [
        ...board.querySelectorAll("[player=p2]"),
      ]);
      return { pion: p2PionEl, motionPoint: motionPointEl };
    }

    // Defend, IF player 1 has victory points, then player 2 move to player 1 victory points
    const { winningCoord: p1WinningCoord } = findWinningCoords(
      p1PionCoords,
      mPCoords
    );
    for (let p2PionCoord of p2PionCoords) {
      if (pionCanMove(p2PionCoord, p1WinningCoord)) {
        const motionPointEl = findElementByCoord(p1WinningCoord, [
          ...board.querySelectorAll(".motion-point"),
        ]);
        const p2PionEl = findElementByCoord(p2PionCoord, [
          ...board.querySelectorAll("[player=p2]"),
        ]);
        return { pion: p2PionEl, motionPoint: motionPointEl };
      }
    }

    //Moving randomly, If player 1 and player 2 do not have a victory point, then player 2 will move randomly
    while (true) {
      const { randPionCoord, randCoord } = getRandCoord(p2PionCoords, mPCoords);
      if (pionCanMove(randPionCoord, randCoord)) {
        const motionPointEl = findElementByCoord(randCoord, [
          ...board.querySelectorAll(".motion-point"),
        ]);
        const p2PionEl = findElementByCoord(randPionCoord, [
          ...board.querySelectorAll("[player=p2]"),
        ]);
        return { pion: p2PionEl, motionPoint: motionPointEl };
      }
    }
  };

  return { getNextMove };
};

export default InferenceSystem;
