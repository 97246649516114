/**
 *
 * Component to created a Modal
 * @param {object} style - style of the Modal
 * @returns html Element
 * @example
 * <Modal
 *  className = "value"
 *  width = "value"
 *  height = "value"
 *  padding = "value value value value" // Ex: "2px 4px 2px 4px"
 *  margin = "value value value value"  // Ex: "2px 4px 2px 4px"
 *  color = "value"
 *  backgrColor = "value"
 *  backgrImage = "value"
 *  backgrRepeat = "value"
 *  backgrSize = "value" 
 *  bordRadius = "value"
 *  display = "value"
 *  fontSize = "value"
 *  boxShadow = "valueX valueY valueBlur valueSpread valueColor valueShadow" // Ex: "2px 0 px 0 black inset"
 *  fontFamily = "value"
 *  top = "value"
 *  right = "value"
 *  bottom = "value"
 *  left = "value"
 *  transform = "value">
 *  border = "value">
 *  zIndex = "value">
 *  handleClose = "callback" // Ex: toggleModalVisibility
 * <Modal>
 */

const Modal = ({
  name,
  children,
  width,
  height,
  padding,
  margin,
  backgrColor,
  backgrRepeat,
  backgrSize,
  backgrImage,
  bordRadius,
  display,
  boxShadow,
  fontFamily,
  top,
  right,
  bottom,
  left,
  transform,
  position,
  border,
  zIndex = 10,
  handleClose = () =>{},
}) => {
  return (
    <>
      <div
        name={name}
        className={`modal hidden`}
        // onClick={function(e) {
        //   // if(!e.target.classList.contains("modal")) return;

        //   // handleClose?.call(name);
        // }}
      >
        <div
          className={`modal-main  absolute-view-${position}`}
          style={{
            width: width,
            height: height,
            padding: padding,
            margin: margin,
            backgroundColor: backgrColor,
            backgroundImage: backgrImage,
            backgroundRepeat: backgrRepeat,
            backgroundSize: backgrSize,
            borderRadius: bordRadius,
            display: display,
            boxShadow: boxShadow,
            fontFamily: fontFamily,
            position: "absolute",
            top: top,
            right: right,
            bottom: bottom,
            left: left,
            transform: transform,
            border: border,
            zIndex: zIndex,
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
