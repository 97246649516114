import React, { useEffect, useState } from "react";
import Image from "../../components/Image";
import AbsoluteView from "../../components/AbsoluteView";
import Text from "../../components/Text";
import PlayerName from "../../components/boardComponent/PlayerName";
import Scoreboard from "../../components/boardComponent/Scoreboard";
import DamDamanBoard from "../../components/boardComponent/DamDamanBoard";
import Pion from "../../components/boardComponent/Pion";
import MotionPoint from "../../components/boardComponent/MotionPoint";
import Modal from "../../components/Modal";
import StaticView from "../../components/StaticView";
import useSound from "../../hooks/useSound";
import { move, RESET_TIMEOUT_DURATION } from "../../config";
import MatchSession from "../../Storage/sessionStorage/MatchSession";
import { useBeforeUnload, useNavigate } from "react-router-dom";
import CustomLocal from "../../Storage/localStorage/CustomLocal";
import { extractPathSegment } from "../../utils/extractPathSegment";
import InferenceSystem from "../../hooks/InferenceSystem";
import { extractTransformValues } from "../../utils/extractTransformValues";
import { pionCanMove } from "../../utils/pionCanMove";
import checkWinnings from "../../utils/checkWinnings";
import MatchTheme from "../../components/boardComponent/MatchTheme";

function DamDamamPlayView() {
  const mode = extractPathSegment(window.location.pathname, "vsai");
  let selectedPion;
  const [motionPoints, setMotionPoint] = useState(null);
  // const [customLocal, setCustomLocal] = useState = (null);
  const [scoreP1, setScoreP1] = useState(MatchSession.getScore("p1"));
  const [playerTurn, setPlayerTurn] = useState("p1");
  const [scoreP2, setScoreP2] = useState(MatchSession.getScore("p2"));
  const navigate = useNavigate();
  const { getNextMove } = InferenceSystem();
  // const moveSound = useSound(move);

  const moveSound = useSound(move);

  // useEffect(() => {
  //   return (() =>{
  //     moveSound.clear();
  //   })
  // },[])
  useEffect(() => {
    MatchSession.setScore("p1", scoreP1).update();
  }, [scoreP1]);
  useEffect(() => {
    MatchSession.setScore("p2", scoreP2).update();
  }, [scoreP2]);

  useEffect(() => {
    setMotionPoint(document.querySelectorAll(".motion-point"));
    // setCustomLocal(CustomLocal);

  }, []);
  useEffect(() => {
    // Executes the AI's move if the conditions are met.
    try {
      executeAiMove();
      
    } catch (error) {
      console.log(error);
    }
  }, [playerTurn]);

  useBeforeUnload((event) => {
    const message = "Are you sure you want to leave? You have unsaved changes.";
    event.preventDefault();
    event.returnValue = message; // Older browsers
    return message; // Modern browsers
  });
  const switchOnPion = function (pion) {
    document.querySelector(".pion-selected")?.classList.remove("pion-selected");

    pion.classList.add("pion-selected");
  };
  const hiddenMotionPoint = function () {
    motionPoints.forEach((mp) => mp.classList.add("hidden"));
  };
  const showMotionPoint = function (pion) {
    const pionCoord = extractTransformValues(pion.style.transform);
    motionPoints.forEach((mP) => {
      const mPCoord = extractTransformValues(mP.style.transform);
      if (pionCanMove(pionCoord, mPCoord)) {
        mP.classList.remove("hidden");
      }
    });
  };
  const executeAiMove = function () {
    if (mode === "vsai" && playerTurn === "p2") {
      const board = document.getElementById("board");

      // Get the pion the next move use the Rule base system
      const { pion, motionPoint } = getNextMove(board);

      // Select the AI's pion after a delay of 2 seconds
      setTimeout(() => {
        if (playerTurn !== "p2") return;
        selectPion(pion);
      }, 2000);
      // Move the selected pion to the calculated motion point after an additional delay of 1 second
      setTimeout(() => {
        if (playerTurn !== "p2") return;
        movePion(motionPoint);
      }, 3000);
    }
  };
  const toggleModal = function (e) {
    const connect = e.target.getAttribute("connect");
    document
      .querySelector(`[name=${connect}]`)
      ?.classList.toggle(
        `${connect !== "modal-wins" ? "modal-show" : "modal-animation"}`
      );
  };
  const swapPionPosition = function (motionPoint) {
    const tempSelectedPionTransform = selectedPion.style.transform;

    selectedPion.style.transform = motionPoint.style.transform;

    motionPoint.style.transform = tempSelectedPionTransform;
  };
  const deselectPion = function () {
    selectedPion?.classList.remove("pion-selected");
  };
  const switchPlayer = function () {
    setPlayerTurn(playerTurn === "p1" ? "p2" : "p1");
  };
  const executeNextMove = function () {
    // Switches the current player.
    switchPlayer();

    // Updates the view to reflect the changes.
    // super._updateView();
  };
  const resetMotionPoints = function () {
    motionPoints.forEach((mP, i) => {
      mP.style.transform = `translate(${i * 150}px, 150px)`;
      mP.classList.add("hidden");
    });
  };
  const togglePionAnimation = function (player) {
    document
      .querySelectorAll(`[player=${player}]`)
      .forEach((playerPion) =>
        playerPion.classList.toggle("pion-winsAnimation")
      );
  };
  const toggleModalAnimation = function () {
    document
      .querySelector("[name=modal-wins]")
      .classList.toggle("modal-animation");
  };
  const resetPions = function (player) {
    document.querySelectorAll(`[player=${player}]`).forEach((playerPion, i) => {
      playerPion.style.transform = `translate(${i * 150}px, ${
        player === "p1" ? 300 : 0
      }px)`;
      playerPion.classList.remove("pion-selected");
    });
  };
  const resetPionAnimation = function () {
    document
      .querySelectorAll(`.pion`)
      .forEach((playerPion) =>
        playerPion.classList.remove("pion-winsAnimation")
      );
  };
  const resetGame = function () {
    togglePionAnimation(playerTurn);
    resetMotionPoints();
    resetPions("p1");
    resetPions("p2");
    switchPlayer();
    // super._updateView();
    // Executes the AI's move if the conditions are met.
    executeAiMove();
  };
  const restartGame = function (e) {
    setScoreP1(0);
    setScoreP2(0);
    toggleModal(e);
    resetPionAnimation();
    resetMotionPoints();
    resetPions("p1");
    setPlayerTurn("p1");
    resetPions("p2");
    // super._updateView();
    // Executes the AI's move if the conditions are met.
    // this._executeAiMove();
  };
  const handleWinScenario = function () {
    togglePionAnimation(playerTurn);

    // Round 1
    if (MatchSession.getRound() === 1) {
      toggleModalAnimation();
    }

    //Round 3
    if (MatchSession.getRound() === 3) {
      const newScore = MatchSession.getScore(playerTurn) + 1;
      playerTurn === "p1" ? setScoreP1(newScore) : setScoreP2(newScore);

      if (newScore >= 2) {
        toggleModalAnimation();
        return;
      }
      setTimeout(resetGame, RESET_TIMEOUT_DURATION);
    }

    // Round 5
    if (MatchSession.getRound() === 5) {
      const newScore = MatchSession.getScore(playerTurn) + 1;
      playerTurn === "p1" ? setScoreP1(newScore) : setScoreP2(newScore);

      if (newScore >= 3) {
        toggleModalAnimation();
        return;
      }
      setTimeout(resetGame, RESET_TIMEOUT_DURATION);
    }
  };
  const movePion = function (motionPoint) {
    swapPionPosition(motionPoint);

    moveSound.play();

    hiddenMotionPoint();

    deselectPion();

    const playerPionsCoord = [
      ...document.querySelectorAll(`[player=${playerTurn}]`),
    ]
      .map((playerPion) => extractTransformValues(playerPion.style.transform))
      .sort((a, b) => a.x - b.x || a.y - b.y);

    if (!checkWinnings(playerPionsCoord)) {
      executeNextMove();
      return;
    }

    handleWinScenario();
  };
  const selectPion = function (pion) {
    switchOnPion(pion);
    hiddenMotionPoint();
    showMotionPoint(pion);
    selectedPion = pion;
  };
  return (
    <>
      <MatchTheme url={`/img/match-theme-${CustomLocal.getMatchTheme()}.png`} />
      <AbsoluteView position="middlecenter">
        <PlayerName
          text={`P2 ${CustomLocal.getPlayerName("p2")}`}
          player="p2"
          playerTurn={playerTurn}
        />

        <StaticView
          display="flex"
          flexDirection="row-reverse"
          alignItems="center"
        >
          <Scoreboard
            scoreP2={scoreP2}
            scoreP1={scoreP1}
            round={MatchSession.getRound()}
          />
          <DamDamanBoard id={"board"}>
            <Pion
              player={"p2"}
              src={`/icn/pion-${CustomLocal.getPionColor("p2")}.svg`}
              width="28px"
              transform="translate(0, 0)"
              cursor={`${playerTurn === "p2" ? "pointer" : "auto"}`}
              onClick={(e) => {
                console.log(playerTurn);
                if (playerTurn === "p1" || mode === "vsai") return;
                selectPion(e.target);
              }}
            />
            <Pion
              player={"p2"}
              src={`/icn/pion-${CustomLocal.getPionColor("p2")}.svg`}
              width="28px"
              transform="translate(150px, 0)"
              cursor={`${playerTurn === "p2" ? "pointer" : "auto"}`}
              onClick={(e) => {
                if (playerTurn === "p1" || mode === "vsai") return;
                selectPion(e.target);
              }}
            />
            <Pion
              player={"p2"}
              src={`/icn/pion-${CustomLocal.getPionColor("p2")}.svg`}
              width="28px"
              transform="translate(300px, 0)"
              cursor={`${playerTurn === "p2" ? "pointer" : "auto"}`}
              onClick={(e) => {
                if (playerTurn === "p1" || mode === "vsai") return;
                selectPion(e.target);
              }}
            />
            <Pion
              player={"p1"}
              src={`/icn/pion-${CustomLocal.getPionColor("p1")}.svg`}
              width="28px"
              transform="translate(0, 300px)"
              cursor={`${playerTurn === "p1" ? "pointer" : "auto"}`}
              onClick={(e) => {
                if (playerTurn === "p2") return;
                selectPion(e.target);
              }}
            />
            <Pion
              player={"p1"}
              src={`/icn/pion-${CustomLocal.getPionColor("p1")}.svg`}
              width="28px"
              transform="translate(150px, 300px)"
              cursor={`${playerTurn === "p1" ? "pointer" : "auto"}`}
              onClick={(e) => {
                if (playerTurn === "p2") return;
                selectPion(e.target);
              }}
            />
            <Pion
              player={"p1"}
              src={`/icn/pion-${CustomLocal.getPionColor("p1")}.svg`}
              width="28px"
              transform="translate(300px, 300px)"
              cursor={`${playerTurn === "p1" ? "pointer" : "auto"}`}
              onClick={(e) => {
                if (playerTurn === "p2") return;
                selectPion(e.target);
              }}
            />

            <MotionPoint
              className="hidden"
              width="28px"
              height="28px"
              backgrColor="rgba(128,128,128,0.4)"
              transform="translate(0px, 150px)"
              onClick={(e) => {
                movePion(e.target);
              }}
            />
            <MotionPoint
              className="hidden"
              width="28px"
              height="28px"
              backgrColor="rgba(128,128,128,0.4)"
              transform="translate(150px, 150px)"
              onClick={(e) => {
                // if (
                //   MatchSession.getGameMode() === "vsai" &&
                //   this._playerTurn === "p2"
                // )
                //   return;
                movePion(e.target);
              }}
            />
            <MotionPoint
              className="hidden"
              width="28px"
              height="28px"
              margin="0 24px 0 0"
              backgrColor="rgba(128,128,128,0.4)"
              transform="translate(300px, 150px)"
              onClick={(e) => {
                movePion(e.target);
              }}
            />
          </DamDamanBoard>
        </StaticView>

        <PlayerName
          text={`P1 ${CustomLocal.getPlayerName("p1")}`}
          playerTurn={playerTurn}
          player="p1"
        />
      </AbsoluteView>

      <AbsoluteView padding="30px">
        <Image
          connect="pause-modal"
          src={"/icn/icon-pause.svg"}
          cursor="pointer"
          bordRadius="8px"
          width="28px"
          className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
          onClick={(e) => {
            toggleModal(e);
          }}
        />
      </AbsoluteView>

      <Modal
        name="pause-modal"
        position="middlecenter"
        width="max-content"
        padding="0 32px"
        bordRadius="8px"
        transition="all 0s 0s linear"
      >
        <AbsoluteView
          transform="translate(-50% ,-50%)"
          padding="10px 20px"
          position="topcenter"
          bordRadius="8px"
          backgrColor="#E0E0E0"
        >
          <Text text="Pause" width="max-content" />
        </AbsoluteView>

        <StaticView margin="40px auto 20px auto" width="max-content">
          <Image
            src={"/icn/icon-resume.svg"}
            connect="pause-modal"
            width="32px"
            bordRadius="8px"
            backgrColor="#D9D9D9"
            cursor="pointer"
            margin="0 15px 0 0"
            className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
            filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"
            onClick={(e) => {
              toggleModal(e);
            }}
          />
          <Image
            src={"/icn/icon-restart.svg"}
            connect="pause-modal"
            width="32px"
            bordRadius="8px"
            backgrColor="#D9D9D9"
            cursor="pointer"
            margin="0 15px 0 0"
            className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
            onClick={(e) => {
              restartGame(e);
            }}
          />
          <Image
            src={"/icn/icon-home-fill.svg"}
            width="32px"
            height="32px"
            bordRadius="8px"
            backgrColor="#D9D9D9"
            cursor="pointer"
            className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
            onClick={() => {
              navigate("/home");
              MatchSession.clear().update();
            }}
          />
        </StaticView>
      </Modal>

      <Modal
        name="modal-wins"
        position="middlecenter"
        width="350px"
        bordRadius="8px"
      >
        <Image src={"/img/trophy-avif.avif"} width="100%" bordRadius="8px" />
        <Text
          text={`${CustomLocal.getPlayerName(playerTurn)} Menang!`}
          width="100%"
          fontSize="14px"
          maxWidth="300px"
          margin="0 auto"
          textAlign="center"
          padding="10px"
          lineHeight="150%"
        />
        <StaticView margin="10px auto" width="max-content">
          <Image
            connect="modal-wins"
            src={"/icn/icon-restart.svg"}
            width="32px"
            bordRadius="8px"
            backgrColor="#D9D9D9"
            cursor="pointer"
            margin="0 15px 0 0"
            className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
            onClick={(e) => {
              restartGame(e);
            }}
          />
          <Image
            src={"/icn/icon-home-fill.svg"}
            width="32px"
            bordRadius="8px"
            backgrColor="#D9D9D9"
            cursor="pointer"
            className="btn-pseudo-hover btn-pseudo-active btn-bxShadow-0242-black-inset"
            onClick={() => {
              navigate("/home");
              MatchSession.clear().update();
            }}
          />
        </StaticView>
        <Image src={"/img/rocket-avif.avif"} width="100%" bordRadius="8px" />
      </Modal>
    </>
  );
}

export default DamDamamPlayView;
