import { CUSTOM_SESSION_KEY } from "../../config";

class CustomLocal {
  _customLocal;
  constructor() {
    this._createSessionPref();
    
    this._customLocal = JSON.parse(localStorage.getItem(CUSTOM_SESSION_KEY));
  }
  update() {
    localStorage.setItem(
      CUSTOM_SESSION_KEY,
      JSON.stringify(this._customLocal)
    );
  }
  setPionColor(color, player) {
    this._customLocal[`pionColor${player.toUpperCase()}`] = color;
    return this;
  }
  getPionColor(player) {
    return this._customLocal[`pionColor${player.toUpperCase()}`];
  }
  setMatchTheme(theme) {
    this._customLocal.matchTheme = theme;
    return this;
  }
  getMatchTheme() {
    return this._customLocal.matchTheme;
  }
  setPlayerName(player, name) {
    this._customLocal[`name${player.toUpperCase()}`] = name;
    return this;
  }
  getPlayerName(player) {
    return this._customLocal[`name${player.toUpperCase()}`];
  }
  _createSessionPref() {
    if (localStorage.getItem(CUSTOM_SESSION_KEY)) return;

    localStorage.setItem(
      CUSTOM_SESSION_KEY,
      JSON.stringify({
        pionColorP1 : "gray",
        pionColorP2 : "brown",
        matchTheme : "batik1",
        nameP1 : "player 1",
        nameP2 : "player 2",
      })
    );
  }
  clear() {

    return this;
  }
}

export default new CustomLocal();
