import { Link } from "react-router-dom";

/**
 * Component to create a button
 * @param {object} style - style of the button
 * @returns Html elements
 * @example
 * <Button
 * className = "value"
 *  text = "value"
 *  width = "value"
 *  height = "value"
 *  padding = "value value value value" // Ex: "2px 4px 2px 4px"
 *  margin = "value value value value"  // Ex: "2px 4px 2px 4px"
 *  color = "value"
 *  backgrColor = "value"
 *  bordRadius = "value"
 *  display = "value"
 *  fontSize = "value"
 *  boxShadow = "valueX valueY valueBlur valueSpread valueColor valueShadow" // Ex: "2px 0 px 0 black inset"
 *  fontFamily = "value"
 *  filter = "value"
 *  flex= "value"
 *  onClick = "value"
 *  href = "value"
 *  disabled = "value" // Ex : "false"
 *  textDecoration = "value"
 * />
 */
function Button({
  className,
  text = "Button",
  all,
  width,
  height,
  padding,
  margin,
  color,
  backgrColor,
  bordRadius,
  display = "block",
  fontSize,
  boxShadow,
  filter,
  fontFamily,
  fontWeight,
  flex,
  textDecoration = "none",
  disabled = false,
  icon,
  onClick,
  href,
}) {
  return (
    <Link
      className={`btn btn-wrap btn-pad-12-ver btn-pad-20-hor ${className} ${
        disabled ? "btn-disabled" : ""
      }`}
      style={{
        all: all,
        width: width,
        height: height,
        padding: padding,
        margin: margin,
        backgroundColor: backgrColor,
        color: disabled === false ? color : "",
        borderRadius: bordRadius,
        display: display,
        fontSize: fontSize,
        boxShadow: boxShadow,
        filter: filter,
        fontFamily: fontFamily,
        flex: flex,
        fontWeight : fontWeight,
        textDecoration: textDecoration,
      }}
      onClick={onClick}
      to={href}
    >
      {icon ? <img src={icon} className="btn-icon" /> : ""}
      {text}
    </Link>
  );
}

export default Button;
