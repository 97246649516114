export const TIME_LONG = 7500;
export const APP_PREF_LOCAL_KEY = "app-local-pref";
export const APP_LOCAL_KEY = "app";
export const MATCH_SESSION_KEY = "match";
export const CUSTOM_SESSION_KEY = "custom";
export const ROUND_ONE = 1;
export const ROUND_THREE = 3;
export const ROUND_FIVE = 5;
export const RESET_TIMEOUT_DURATION = 4000;
export const click = `${process.env.PUBLIC_URL}/sound/mouse-clicks.mp3`;
export const move = `${process.env.PUBLIC_URL}/sound/depositphotos_royaty_532933638-track-sliding-plastic-piece-top-game.mp3`;
export const victoryPoint = [
    // Horizontal victory condition
    { pX1: 0, pY1: 150, pX2: 150, pY2: 150, pX3: 300, pY3: 150 },
  
    // Vertical victory conditions
    { pX1: 0, pY1: 0, pX2: 0, pY2: 150, pX3: 0, pY3: 300 },
    { pX1: 150, pY1: 0, pX2: 150, pY2: 150, pX3: 150, pY3: 300 },
    { pX1: 300, pY1: 0, pX2: 300, pY2: 150, pX3: 300, pY3: 300 },
  
    // Diagonal victory conditions
    { pX1: 0, pY1: 300, pX2: 150, pY2: 150, pX3: 300, pY3: 0 },
    { pX1: 0, pY1: 0, pX2: 150, pY2: 150, pX3: 300, pY3: 300 },
  ];