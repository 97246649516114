import React, { useEffect } from "react";
import Image from "../../components/Image";
import AbsoluteView from "../../components/AbsoluteView";
import Text from "../../components/Text";
import StaticView from "../../components/StaticView";
import useSound from "../../hooks/useSound";
import { click } from "../../config";
import { useNavigate } from "react-router-dom";

function AudioView() {
  const clickSound = useSound(click);
  const navigate = useNavigate();
  
  const updateVolumeSlider = function() {
    const volumeSlider = document.getElementById("volume-slider");

    const value = volumeSlider.value;
    const min = volumeSlider.min;
    const max = volumeSlider.max;
    const percent = ((value - min) / (max - min)) * 100;
    volumeSlider.style.background = `linear-gradient(to right, #00ffcc ${percent}%, #fff ${percent}%)`;
  }

  useEffect(() =>{
    updateVolumeSlider();
  })
  return (
    <>
      <AbsoluteView position="topleft" padding="1.5rem">
        <Image
          src={"/icn/arrow-back.svg"}
          width="1.5rem"
          cursor="pointer"
          onClick={() => {
            clickSound.play();
            navigate(-1);
          }}
        />
      </AbsoluteView>

      <StaticView
          margin="100px auto 0 auto"
          width="300px"
          bordRadius="20px"
          height="300px"
          backgrColor="#ECB801"
        >
          <Text
            text="Audio"
            padding="30px 0 28px 0"
            textAlign="center"
            fontSize="32px"
            fontWeight="bold"
          />
          <Text
            text="Volume"
            padding="0"
            textAlign="center"
            fontSize="20px"
            fontWeight="700"
            margin = "0 0 20px 0"
          />

          <div className="volume-control">
            <button id="volume-down" className="volume-button">
              🔈
            </button>
            <input
              type="range"
              id="volume-slider"
              className="volume-slider"
              min="0"
              max="100"
              // value="90"
              onInput={updateVolumeSlider}
            />
            <button id="volume-up" className="volume-button">
              🔊
            </button>
          </div>

          {/* <input class="settings-page__input-range" type="range" name="" id=""></input> */}
        </StaticView>

      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        rotate="90deg"
        transform="translate(200px, -30%)"
        top="-60px"
        right="-60px"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
      <Image
        src={"/img/circle-batik.png"}
        width="212px"
        position="fixed"
        bottom="-60px"
        left="-60px"
        rotate="-90deg"
        animation="rotate 15s linear infinite"
        zIndex="-10"
      />
    </>
  );
}

export default AudioView;
