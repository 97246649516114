/**
 * Component to create a image
 * @param {object} style - style of the image
 * @returns Html elements
 * @example
 * <Image
 *  src = "value"
 *  onClick = "callback"
 *  className = "value"
 *  type = "value"
 *  name = "value"
 *  id = "value"
 *  width = "value"
 *  height = "value"
 *  padding = "value value value value" // Ex: "2px 4px 2px 4px"
 *  margin = "value value value value"  // Ex: "2px 4px 2px 4px"
 *  backgrColor = "value"
 *  backgrImage = "value"
 *  backgrRepeat = "value"
 *  backgrSize = "value"
 *  bordRadius = "value"
 *  display = "value"
 *  fontSize = "value"
 *  boxShadow = "valueX valueY valueBlur valueSpread valueColor valueShadow" // Ex: "2px 0 px 0 black inset"
 *  opacity= "value"
 *  cursor = "value"
 *  animation = "value"
 *  position = "value"
 *  top = "value"
 *  right = "value"
 *  bottom = "value"
 *  left = "value"
 *  border = "value"
 * />
 */

import { useState } from "react";

const Input = ({
  src,
  onClick,
  className,
  type,
  name,
  id,
  maxLength,
  value,
  width,
  height,
  padding,
  margin,
  backgrColor,
  backgrRepeat,
  backgrSize,
  backgrImage,
  bordRadius,
  display,
  boxShadow,
  opacity,
  cursor,
  animation,
  position,
  top,
  right,
  bottom,
  left,
  textAlign,
  border,
  onChange = () => {},
  onBlur = () => {},
}) => {
    const [inputValue, setInputValue] = useState(value);
  return (
    <>
      <input
        className={className}
        onClick={onClick}
        type={type}
        name={name}
        id={id}
        maxLength={maxLength}
        value={inputValue}
        onChange={(event) => {
          setInputValue(event.target.value);
          onChange();
        }}
        onBlur={onBlur}
        style={{
          width: width,
          height: height,
          padding: padding,
          margin: margin,
          backgroundColor: backgrColor,
          backgroundImage: backgrImage,
          backgroundRepeat: backgrRepeat,
          backgroundSize: backgrSize,
          borderRadius: bordRadius,
          display: display,
          boxShadow: boxShadow,
          opacity: opacity,
          cursor: cursor,
          animation: animation,
          position: position,
          top: top,
          right: right,
          bottom: bottom,
          left: left,
          textAlign: textAlign,
          border: border
        }}
      />
    </>
  );
};

export default Input;
